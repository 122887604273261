import React from 'react'
import { Link } from 'gatsby'
import { Navigation, NavigationItem } from './navigationStyles.js'

export default () => (
  <nav role="navigation">
    <Navigation>
      <NavigationItem>
        <Link activeClassName="active" to="/">
          Home
        </Link>
      </NavigationItem>
      <NavigationItem>
        <Link activeClassName="active" to="/blog/">
          Blog
        </Link>
      </NavigationItem>
    </Navigation>
  </nav>
)
