import React from 'react'

import {
  Hero,
  HeroImage,
  HeroDetails,
  HeroHeadline,
  HeroTitle,
} from './heroStyles.js'

export default ({ data }) => (
  <Hero>
    <HeroImage alt={data.name} fluid={data.heroImage.fluid} />
    <HeroDetails>
      <HeroHeadline>{data.name}</HeroHeadline>
      <HeroTitle>{data.title}</HeroTitle>
      <p>{data.shortBio.shortBio}</p>
    </HeroDetails>
  </Hero>
)
